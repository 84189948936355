import { type ComponentType, lazy } from 'react'
import { runAsyncWithRetries } from './utils/promises'

export type ImportReturnType = { default: ComponentType<any> }

// This function can't call import func directly because the path is relative to the caller
export function lazyImport(
  importFunc: () => Promise<ImportReturnType>,
  componentName: string
) {
  return lazy(() => retryImport<ImportReturnType>(importFunc, componentName))
}

export function retryImport<T>(
  importFunc: () => Promise<T>,
  componentName: string
): Promise<T> {
  return runAsyncWithRetries<T>({
    op: importFunc,
    opName: `import ${componentName}`,
    logRetryAsError: false,
  }).catch((e) => {
    setTimeout(() => {
      if (import.meta.env.PROD) {
        window.location.reload()
      }
    })
    throw e
  })
}
